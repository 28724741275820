<template />

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      lhnWebsites: [
        '2b918be2-c66c-4aa8-bcce-052677c1f9fa', // northwestregisteredagent.com
      ],
      lhnBuilder: new window.LiveHelpNowBuilder(),
    }
  },

  computed: {
    ...mapGetters('website', ['website']),
    ...mapGetters('account', ['account', 'actualAccountID']),

    requiredDataHasLoaded() {
      return (
        this.account !== null &&
        this.account.person_emails &&
        this.website !== null &&
        this.website.id &&
        this.actualAccountID
      )
    },
  },

  watch: {
    requiredDataHasLoaded(itHasLoaded) {
      if (itHasLoaded) {
        this.setupLiveHelpNow()
      }
    },
  },

  mounted: async function() {
  },

  methods: {
    setupLiveHelpNow: function() {
      // enabled for all environments, but can gate to just production with the line below if needed
      // if (process.env.NODE_ENV === 'production') {
        if (this.lhnWebsites.includes(this.website.id)) {
          const first_name = this.account.first_name
          const last_name = this.account.last_name
          const full_name = [first_name, last_name].filter(Boolean).join(' ')

          const email = this.account.person_emails[0]
          const email_address = email?.email_address || null

          this.lhnBuilder.init({
            fieldData: {
              full_name: full_name,
              email: email_address,
            },
            options: {
              custom1Label: "Customer Website",
              custom1: this.website.url,
              custom2Label: "Corp Tools Log in: ",
              custom2: `https://${this.actualAccountID}.at.two.onlineaccount.net`,
            },
          })
        }
      // }
    },
  },
}
</script>